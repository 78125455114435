<template>
  <b-form-group
    :label-for="fieldName"
    :label="label"
  >
    <v-select
      :id="fieldName"
      v-model="model"
      :options="options"
      :placeholder="label"
    />
    <small
      v-if="validationContext"
      class="text-danger"
    >
      {{ validationContext.errors[0] }}
    </small>
  </b-form-group>
</template>

<script>
export default {
  name: 'ProductAttributeSelect',
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    validationContext: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
    fieldName() {
      // eslint-disable-next-line no-underscore-dangle
      return `${this.label}_${this._uid}`
    },
  },
  methods: {
    onChange(eventPayload) {
      this.$emit('change', eventPayload)
    },
  },
}
</script>

<style scoped>
</style>
